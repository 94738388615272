import { Box } from "@mui/material";
import { ComponentType, PropsWithChildren } from "react";
import { StampGiftsViewType } from "../app/StampGifts";
import { StampGift } from "../types/stampGift";

const StampGiftsView: StampGiftsViewType = ({ list, onClickItem }) => {
    return (
        <div>
            {list.map(item => (
                <Item key={item.id} item={item} onClick={() => onClickItem(item)} />
            ))}
        </div>
    )
}

export default StampGiftsView

const Item: ComponentType<{ item: StampGift, onClick: () => any }> = ({ item: gift , onClick}) => {
    return (
        <Row onClick={() => {
            onClick()
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                borderBottom: 'solid 1px rgba(0,0,0,0.2)',
                pt: 1,
                pb: 1,
            }}>
                <Box sx={{
                    display: 'flex',
                    flexGrow: 1,
                    p: 1,
                    fontSize: '1.2em',
                }}>{gift.name}</Box>
                <Box
                    sx={{
                        p: 1,
                        pr: 2,
                        fontSize: '0.8em'
                    }}
                >{gift.point}pt</Box>
            </Box>
        </Row>
    )
}

const Row: ComponentType<PropsWithChildren<{onClick: ()=>any}>> = ({ children, onClick }) => {
    return (
        <Box sx={{ p: 1 }} onClick={() => onClick()}>
            {children}
        </Box>
    )
}
