import { Add, Remove } from "@mui/icons-material"
import { Box, Button, IconButton, Typography, TextField as MuiTextField } from "@mui/material"
import { ComponentType, PropsWithChildren, useEffect, useState } from "react"
import { StaffDetailViewType } from "../app/StaffDetail"
import { Main } from "./user/container"
import { TextField } from "./user/form"
import { getStaffImageSource } from "./util"

const blankImage = require('./images/blank400300.png')
const noImage = require('./images/noimg.png')

const StaffDetailView: StaffDetailViewType = ({ staff: item, amount, setAmount,disableDecrementButton, disableSendButton, onDecrementAmount, onIncrementAmount, onSend, isSendComplete, onClearIsSendComplete, amountList }) => {
    useEffect(() => {
        window.document.title = 'チップを送る'
    }, [])
    const [message, setMessage] = useState("")

    const renderImage = () => {
        return (
            <Box
                component="img"
                className="menu_img"
                src={blankImage}
                sx={{
                    background: `url(${getStaffImageSource(item)}) center center no-repeat`,
                    backgroundSize: 'cover',
                }}
            />
        )
    }
    const renderSendComplete = () => {
        return (<>
            <Row>
                <p>「{item.name}」にチップを送りました！</p>
            </Row>
            <Row>
                <Button onClick={() => onClearIsSendComplete()}>戻る</Button>
            </Row>
        </>)
    }
    const renderForm = () => {
        if (!isSendComplete) {
            return (
                <>
                    <Row>
                        <MuiTextField
                            value={message}
                            onChange={evt => setMessage(evt.target.value)} label="メッセージ"
                            rows={5}
                        />
                    </Row>
                    <Row>
                        {amountList.map(amountValue => (
                            <Button sx={{ mr: 1, mb: 1 }} onClick={() => setAmount(amountValue)} variant="outlined" color={amountValue === amount ? "secondary" : "primary"} size="small">￥{amountValue}</Button>
                        ))}
                    </Row>
                    <Row>
                        {/* <TextField value={`${amount}`} onChange={onChangeAmount} label="金額" /><IconButton onClick={onIncrementAmount}><Add /></IconButton> <IconButton onClick={onDecrementAmount} disabled={disableDecrementButton}><Remove /></IconButton> */}
                        <Button variant="contained" onClick={() => onSend()} disabled={disableSendButton}>チップを送る</Button>
                    </Row>
                </>
            )
        } else {
            return renderSendComplete()
        }
    }
    return (
        /*
        <Main>
            <Row>
                <Typography variant="h4">{item.name}</Typography>
            </Row>
            <Row>
                {renderImage()}
            </Row>
            <Row>
                <Box component="p" sx={{ width: '100%', overflowWrap: "break-word", whiteSpace: "pre-line" }}>
                    {item.description}
                </Box>
            </Row>
            {renderForm()}
        </Main>
        */
        <section className="menu_single color019">
            <div className="menu_block">
                <h2>{item.name}</h2>

                {renderImage()}
                <div className="text">
                    {item.description}
                </div>
            </div>
            {renderForm()}
        </section>
    )
}

export default StaffDetailView

const Row: ComponentType<PropsWithChildren<{}>> = ({ children }) => {
    return (
        <Box sx={{ flex: 1, padding: 2, overflowWrap: 'break-word' }}>
            {children}
        </Box>
    )
}