import { ComponentType, createContext, PropsWithChildren, useContext } from "react";
import { Server } from "../../types/server";

export const ServerProvider:ComponentType<PropsWithChildren<Props>> = ({server, children}) => {
    const value:Value = {
        server,
    }
    return (
        <context.Provider value={value}>
            {children}
        </context.Provider>
    )
}

export const useServer = ():Server => {
    return useContext(context)!.server
}

interface Props {
    server: Server,
}

interface Value {
    server: Server,
}

const context = createContext<Value|null>(null)
