import { ComponentType, createContext, PropsWithChildren, useCallback, useContext, useState } from "react"
import { Product } from "../../types/product"

const ReserveProvider: ComponentType<PropsWithChildren<Props>> = ({ children }) => {
    const [items, setItems] = useState<{ product: Product, num: number }[]>([])
    const addToCart = useCallback(async (product: Product, num: number) => {
        setItems(items => ([
            ...items,
            { product, num },
        ]))
    }, [])
    const clearCart = useCallback(async () => {
        setItems([])
    }, [])
    const value: Value = {
        addToCart,
        clearCart,
        cartItems: items,
    }
    return (
        <context.Provider value={value}>
            {children}
        </context.Provider>
    )
}

interface Props { }

export default ReserveProvider

export const useReserve = () => useContext(context)!

interface Value {
    addToCart(product: Product, num: number): Promise<void>
    clearCart(): Promise<void>
    cartItems: {product:Product, num:number}[]
}

const context = createContext<Value | undefined>(undefined)

