import { ComponentType, createContext, Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import liff from '@line/liff';

export const LiffProvider: ComponentType<Props> = ({ children }) => {
    const [isReady, setIsReady] = useState(false)

    const initLiff = useCallback(() => {
        if (process.env.REACT_APP_IS_LOCAL_STANDALONE !== 'yes') {
            liff.init({ liffId: process.env.REACT_APP_LIFF_ID as string })
                .then(() => {
                    setIsReady(true)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [])
    const getIDToken = useCallback(() => {
        if (!isReady) {
            throw new Error("liff is not ready")
        }
        const idToken = liff.getIDToken()
        if (idToken == null) {
            throw new Error("id token is null")
        }
        return idToken
    }, [isReady])
    const value = {
        isReady,
        setIsReady,
        initLiff,
        getIDToken,
    }
    return (
        <context.Provider value={value}>{children}</context.Provider>
    )
}

interface Props {
}

export const useLiff = () => {
    return useContext(context)!
}

const context = createContext<Value | undefined>(undefined)

interface Value {
    isReady: boolean
    setIsReady: Dispatch<SetStateAction<boolean>>
    initLiff: () => any
    getIDToken: () => string
}
