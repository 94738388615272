import { Box } from "@mui/material"
import { useEffect } from "react"
import { ProductsViewType } from "../app/Products"
import { Product } from "../types/product"


const blankImage = require('./images/blank400300.png')

const ProductsView: ProductsViewType = ({ list, total, onClick, title }) => {
    const renderImage = (item: Product) => {
        if (item.image == null) {
            return (
                <Box
                    component="img"
                    src={blankImage}
                    alt=""
                    sx={{
                        width: '100%',
                    }}
                />
            )
        }

        return (
            <Box
                component="img"
                src={blankImage}
                alt=""
                sx={{
                    width: '100%',
                    background: `url(${item.image.url}) center center no-repeat`,
                    backgroundSize: 'cover',
                }}
            />
        )
    }
    useEffect(() => {
        window.document.title = title
    }, [title])
    return (
        <section className="menu_list ">

            <div className="menu_block">
                <h2>{title}</h2>

                <ul className="list">
                    {list.map(item => (
                        <li onClick={() => onClick(item)}>
                            {renderImage(item)}
                            <span className="title">{item.label}</span>
                            <span className="price">{item.price}円(税込)</span>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default ProductsView
