import { Box, Button, Typography } from "@mui/material";
import { TableOrderPaymentViewType } from "../app/TableOrderPayment";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { ComponentType } from "react";

const TableOrderPaymentView: TableOrderPaymentViewType = ({ seatPayment: item, onClickCardPayment }) => {
    if (item == null) {
        return <></>
    }
    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>メニュー</TableCell>
                            <TableCell>価格</TableCell>
                            <TableCell>数量</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item.tableOrders.map(item => (
                            <TableRow key={item.id}>
                                <TableCell>{item.product.name}</TableCell>
                                <TableCell>{item.product.price}円</TableCell>
                                <TableCell>{item.num}</TableCell>
                            </TableRow>
                        ))}
                        {item.tips.map(item => (
                            <TableRow key={item.id}>
                                <TableCell colSpan={2}>{item.staff.name}へのチップ</TableCell>
                                <TableCell>{item.amount}円</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={2}>合計</TableCell>
                            <TableCell>{item.totalAmount}円</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>(内消費税)</TableCell>
                            <TableCell >{item.tax}円</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {/*
            <Row1>
                <Typography>レジでお会計</Typography>
                <Row1>
                    このままレジまでお越しください
                </Row1>
            </Row1>
            <Row1>
                <Typography>この場でクレジットカードでお支払い</Typography>
                <Row1>
                    <Button variant="outlined" color="primary" onClick={() => onClickCardPayment()}>オンライン決済へ</Button>
                </Row1>
            </Row1>
            */}
        </Box>
    )
}

export default TableOrderPaymentView

const Row1: ComponentType<{}> = ({ children }) => {
    return (
        <Box sx={{ p: 1 }}>
            {children}
        </Box>
    )
}