import { ComponentType, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Product, ProductGroup } from "../types/product"
import { SearchResult } from '../types/search'
import { Theme } from "../types/theme"
import { ProductGroupsViewType } from "./ProductGroups"
import { useServer } from "./providers/server"
import { useTheme } from "./providers/theme"

const ReserveProductGroups: ComponentType<Props> = ({ View }) => {
    const server = useServer()
    const theme = useTheme()
    const navigate = useNavigate()
    const [{ list, total }, setResult] = useState<SearchResult<ProductGroup>>({ list: [], total: 0 })
    const fetchAsync = useCallback(async () => {
        setResult(await server.searchProductGroups({ page: 1, perPage: 10 }))
    }, [server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    const handleClick = (item: ProductGroup) => {
        navigate(`/reserve/products/group=${item.id}`)
    }
    return (
        <View
            list={list}
            total={total}
            onClick={handleClick}
            theme={theme}
            onClickPayment={() => {
                navigate(`/reserve/new`)
            }}
            paymentButtonLabel="注文する"
            title="事前注文"
        />
    )
}

export default ReserveProductGroups

interface Props {
    View: ProductGroupsViewType
}
