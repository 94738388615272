import { ComponentType, createContext, PropsWithChildren, useCallback, useContext, useState } from "react"
import { Server } from "../../types/server"
import { Session } from "../../types/session"

export const SessionProvider: ComponentType<ProviderProps> = ({ server, children }) => {
    const [state, setState] = useState<{ fetched: boolean, session: Session | null }>({
        fetched: false,
        session: null,
    })
    const { session, fetched } = state
    const fetchCurrentSession = useCallback(async () => {
        try {
            try {
                const session = await server.getCurrentSession()
                console.log({ session })
                setState({ fetched: true, session })
            } catch (error) {
                await server.signOut()
                setState({ fetched: true, session: null })
            }
        } catch (error) {
            setState({ fetched: true, session: null })
        }
    }, [server])
    const setDummySession = useCallback(async () => {
        setState({fetched: true, session: {userId: "9999"}})
    }, [])
    const value: ContextValue = {
        session,
        fetched,
        fetchCurrentSession,
        setDummySession,
    }
    return (
        <context.Provider value={value}>{children}</context.Provider>
    )
}

export const useSession = () => {
    return useContext(context)!
}

type ProviderProps = PropsWithChildren<{
    server: Server
}>

interface ContextValue {
    session: Session | null
    fetched: boolean
    fetchCurrentSession: () => void
    setDummySession: () => void
}

const context = createContext<ContextValue | null>(null)

