import { Box, Button, TextField } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { ComponentType } from "react";
import { ReserveCartViewtype } from "../app/ReserveCart";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {ja} from 'date-fns/locale'

const ReserveCartView: ReserveCartViewtype = ({ name, email, tel, date, setName, setEmail, setTel, setDate, items, onSubmit }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>メニュー</TableCell>
                                <TableCell>価格</TableCell>
                                <TableCell>数量</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, i) => (
                                <TableRow key={`${i}`}>
                                    <TableCell>{item.product.name}</TableCell>
                                    <TableCell>{item.product.price}円</TableCell>
                                    <TableCell>{item.num}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <form>
                    <Row1>
                        <TextField type="text" name="name" label="お名前" onChange={evt => setName(evt.target.value)} value={name} />
                    </Row1>
                    <Row1>
                        <TextField type="text" name="email" label="メールアドレス" onChange={evt => setEmail(evt.target.value)} value={email} />
                    </Row1>
                    <Row1>
                        <TextField type="text" name="tel" label="電話番号" onChange={evt => setTel(evt.target.value)} value={tel} />
                    </Row1>
                    <Row1>
                        <DateTimePicker
                            label="受け取り日時"
                            value={date}
                            onChange={d => {
                                if (d == null) {
                                    setDate(new Date())
                                    return
                                }
                                setDate(d)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Row1>
                    <Row1>
                        <Button variant="outlined" color="primary" onClick={() => onSubmit()}>注文する</Button>
                    </Row1>
                </form>
            </Box>
        </LocalizationProvider>
    )
}

export default ReserveCartView

const Row1: ComponentType<{}> = ({ children }) => {
    return (
        <Box sx={{ p: 1 }}>
            {children}
        </Box>
    )
}