import { Box } from "@mui/material"
import { ComponentType, PropsWithChildren, useEffect } from "react"
import { StaffsViewType } from "../app/Staffs"
import { Staff } from "../types/staff"
import { getStaffImageSource } from "./util"

const blankImage = require('./images/blank400300.png')

const noImage = require('./images/noimg.png')

const StaffsView: StaffsViewType = ({ list, total, onClick }) => {
    useEffect(() => {
        window.document.title = 'チップを送る'
    }, [])

    const renderImage = (item: Staff) => {
        return (
            <Box
            component="img"
            src={blankImage}
            alt=""
            sx={{
                width: '100%',
                background: `url(${getStaffImageSource(item)}) center center no-repeat`,
                backgroundSize: 'cover',
            }}
        />
        )
        /*
        if (item.image == null) {
            return (
                <Box
                    component="img"
                    src={blankImage}
                    alt=""
                    sx={{
                        width: '100%',
                        background: `url(${noImage}) center center no-repeat`,
                        backgroundSize: 'cover',
                    }}
                />
            )
        }

        return (
            <Box
                component="img"
                src={blankImage}
                alt=""
                sx={{
                    width: '100%',
                    background: `url(${item.image.url}) center center no-repeat`,
                    backgroundSize: 'cover',
                }}
            />
        )
        */
    }

    return (
        <section className="menu_list color001">

            <div className="menu_block">
                <h2>チップを送る</h2>

                <ul className="list">
                    {list.map(item => (
                        <li onClick={() => onClick(item)}>
                            {renderImage(item)}
                            <span className="title">{item.name}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default StaffsView

