import { ComponentType, useCallback, useEffect, useState } from "react"
import { useNavigate, useNavigationType, useParams } from "react-router-dom"
import { SearchResult } from "../types/search"
import { SearchStaffOptions, Staff } from "../types/staff"
import { useServer } from "./providers/server"

const Staffs: ComponentType<Props> = ({ View }) => {
    const seatId = useParams().seatId!
    const navigate = useNavigate()
    const server = useServer()
    const [{ list, total }, setResult] = useState<SearchResult<Staff>>({ list: [], total: 0 })
    const fetchAsync = useCallback(async () => {
        setResult(await server.searchStaff({ page: 1, perPage: 10 }))
    }, [server])
    console.log({ list, total })
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    const handleClick = (item: Staff) => {
        navigate(`/seat=${seatId}/staffs/${item.id}`)
    }
    return (
        <View
            list={list}
            total={total}
            onClick={handleClick}
        />
    )
}

export default Staffs

export type StaffsViewType = ComponentType<{
    list: Staff[]
    total: number
    onClick: (item: Staff) => any
}>

interface Props {
    View: StaffsViewType

}