import { CssBaseline } from '@mui/material';
import { ComponentType, FC, useCallback, useEffect } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';
import ApiServer from './api/server';
import { LiffProvider, useLiff } from './app/providers/liff';
import { ServerProvider, useServer } from './app/providers/server';
import { SessionProvider, useSession } from './app/providers/session';
import { ThemeProvider, useThemeContext } from './app/providers/theme';

import { Outlet, Route } from "react-router-dom"
import ProductDetailView from "./views/ProductDetailView"
import ProductGroupsView from "./views/ProductGroupsView"
import ProductsView from "./views/ProductsView"
import StaffDetailView from "./views/StaffDetailView"
import StaffsView from "./views/StaffsView"
import TopView from "./views/TopView"
import ProductDetail from "./app/ProductDetail"
import ProductGroups from "./app/ProductGroups"
import Products from "./app/Products"
import StaffDetail from "./app/StaffDetail"
import Staffs from "./app/Staffs"
import Top from "./app/Top"
import Stamp from "./app/Stamp"
import StampView from "./views/StampView"
import TableOrderPayment from "./app/TableOrderPayment"
import TableOrderPaymentView from "./views/TableOrderPaymentView"
import TableOrderPaymentCard from "./app/TableOrderPaymentCard"
import TableOrderPaymentCardView from "./views/TableOrderPaymentCardView"
import ReserveProducts from "./app/ReserveProducts"
import ReserveProductDetail from "./app/ReserveProductDetail"
import ReserveProductGroups from "./app/ReserveProductGroups"
import ReserveProvider from "./app/providers/reserve"
import ReserveCart from './app/ReserveCart';
import ReserveCartView from './views/ReserveCartView';
import StampGifts from './app/StampGifts';
import StampGiftsView from './views/StampGiftsView';
import StampGiftDetail from './app/StampGiftDetail';
import StampGiftDetailView from './views/StampGiftDetailView';

function App() {
    return (
        <CssBaseline>
            <ServerProvider server={server}>
                <LiffProvider>
                    <LiffLoader>
                        <SessionProvider server={server}>
                            <SessionLoader>
                                <ThemeProvider server={server}>
                                    <ThemeLoading>
                                        <BrowserRouter>
                                            <Routes>
                                                <Route index element={
                                                    <Top View={TopView} />
                                                } />
                                                <Route path="/seat=:seatId/products" element={
                                                    <Products
                                                        viewComponent={ProductsView}
                                                    />
                                                } />
                                                <Route path="/seat=:seatId/products/group=:productGroupId" element={
                                                    <Products
                                                        viewComponent={ProductsView}
                                                    />
                                                } />
                                                <Route path="/seat=:seatId/products/:productId" element={
                                                    <ProductDetail
                                                        View={ProductDetailView}
                                                    />
                                                } />
                                                <Route path="/seat=:seatId/product-groups" element={
                                                    <ProductGroups View={ProductGroupsView} />
                                                } />

                                                <Route path="/seat=:seatId/table-order-payment" element={
                                                    <TableOrderPayment View={TableOrderPaymentView} />
                                                } />
                                                <Route path="/seat=:seatId/table-order-payment/card" element={
                                                    <TableOrderPaymentCard View={TableOrderPaymentCardView} />
                                                } />

                                                <Route path="/reserve/"
                                                    element={
                                                        <ReserveProvider>
                                                            <Outlet />
                                                        </ReserveProvider>
                                                    }
                                                >
                                                    <Route path="/reserve/products" element={
                                                        <ReserveProducts
                                                            viewComponent={ProductsView}
                                                        />
                                                    } />
                                                    <Route path="/reserve/products/group=:productGroupId" element={
                                                        <ReserveProducts
                                                            viewComponent={ProductsView}
                                                        />
                                                    } />
                                                    <Route path="/reserve/products/:productId" element={
                                                        <ReserveProductDetail
                                                            View={ProductDetailView}
                                                        />
                                                    } />
                                                    <Route path="/reserve/product-groups" element={
                                                        <ReserveProductGroups View={ProductGroupsView} />
                                                    } />
                                                    <Route
                                                        path="/reserve/new"
                                                        element={
                                                            <ReserveCart View={ReserveCartView} />
                                                        }
                                                    />
                                                </Route>
                                                <Route path="/seat=:seatId/staffs" element={
                                                    <Staffs
                                                        View={StaffsView}
                                                    />
                                                } />
                                                <Route path="/seat=:seatId/staffs/:staffId" element={
                                                    <StaffDetail
                                                        View={StaffDetailView}
                                                    />
                                                } />
                                                <Route path="/stamp" element={
                                                    <Stamp View={StampView} />
                                                } />
                                                <Route path="/stamp/gifts" element={
                                                    <StampGifts View={StampGiftsView} />
                                                } />
                                                <Route path="/stamp/gifts/:stampGiftId" element={
                                                    <StampGiftDetail View={StampGiftDetailView} />
                                                }></Route>
                                            </Routes>
                                        </BrowserRouter>
                                    </ThemeLoading>
                                </ThemeProvider>
                            </SessionLoader>
                        </SessionProvider>
                    </LiffLoader>
                </LiffProvider>
            </ServerProvider>
        </CssBaseline>
    );
}

export default App;

// const server = new TestingServer(MakeData())

const server = new ApiServer(process.env.REACT_APP_TENANT_ID as string)

const ThemeLoading: FC<{}> = ({ children }) => {
    const { theme } = useThemeContext()
    if (theme == null) {
        return <></>
    }
    return <div className={theme.mainColor}>{children}</div>
}

const LiffLoader: ComponentType<{}> = ({ children }) => {
    const { isReady, initLiff } = useLiff()
    useEffect(() => {
        if (process.env.REACT_APP_IS_LOCAL_STANDALONE !== 'yes') {
            if (!isReady) {
                initLiff()
            }
        }
    }, [initLiff, isReady])
    return (
        <>{children}</>
    )
}

const SessionLoader: ComponentType<{}> = ({ children }) => {
    const server = useServer()
    const { getIDToken, isReady } = useLiff()
    const { fetchCurrentSession, fetched, session, setDummySession } = useSession()
    const signInAsync = useCallback(async () => {
        if (process.env.REACT_APP_IS_LOCAL_STANDALONE !== 'yes') {
            if (!isReady) {
                return
            }
            const idtoken = getIDToken()
            await server.signIn(idtoken)
            await fetchCurrentSession()
        } else {
            await setDummySession()
        }
    }, [fetchCurrentSession, getIDToken, isReady, server, setDummySession])
    useEffect(() => {
        if (!fetched) {
            signInAsync()
        }
    }, [fetched, signInAsync])
    if (!fetched) {
        return <></>
    }
    if (session == null) {
        return <></>
    }
    return <>{children}</>
}
