const avatar01 = require('./avator01.png')
const avatar02 = require('./avator02.png')
const avatar03 = require('./avator03.png')
const avatar04 = require('./avator04.png')
const avatar05 = require('./avator05.png')
const avatar06 = require('./avator06.png')
const avatar07 = require('./avator07.png')
const avatar08 = require('./avator08.png')
const avatar09 = require('./avator09.png')

export const avatars = [
    ['avatar01', avatar01] as const,
    ['avatar02', avatar02] as const,
    ['avatar03', avatar03] as const,
    ['avatar04', avatar04] as const,
    ['avatar05', avatar05] as const,
    ['avatar06', avatar06] as const,
    ['avatar07', avatar07] as const,
    ['avatar08', avatar08] as const,
    ['avatar09', avatar09] as const,
] as const

export type Avatar = typeof avatars[number][0]

export const getAvatarImageSource = (avatar:Avatar|undefined): string =>{
    if (avatar == null) {
        return avatars[0][1]
    }
    return avatars.find(v => v[0] === avatar)![1]
}
