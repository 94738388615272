import { SearchBannersOptions, Banner } from "../types/banner";
import { Image } from "../types/image";
import { Product, ProductGroup, SearchProductGroupOptions, SearchProductOptions, SearchProductResult } from "../types/product";
import { SearchResult } from "../types/search";
import { SeatPayment } from "../types/seatPayment";
import { Server } from "../types/server";
import { Session } from "../types/session";
import { Staff, SearchStaffOptions } from "../types/staff";
import { Stamp } from "../types/stamp";
import { StampGift, SearchStampGiftsOptions } from "../types/stampGift";
import { Theme } from "../types/theme";

class ApiServer implements Server {
    constructor(private readonly tenantId: string) { }

    async signIn(idToken: string): Promise<void> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/sessions`, {
            method: 'POST',
            body: JSON.stringify({
                idToken,
            }),
        })
        if (!resp.ok) {
            throw Error("register error")
        }
    }

    async signOut(): Promise<void> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/sessions/current`, {
            method: 'DELETE',
        })
        if (!resp.ok) {
            throw Error("signOut error")
        }
    }
    async getCurrentSession(): Promise<Session> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/sessions/current`)
        if (!resp.ok) {
            throw Error("getCurrentSession error")
        }
        const id = (await resp.json()).id as string
        return {
            userId: id,
        }
    }

    async getTheme(): Promise<Theme> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/theme`)
        if (!resp.ok) {
            throw Error("api erro")
        }
        return await resp.json()
    }

    async getStaff(staffId: string): Promise<Staff> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/staffs/${staffId}`)
        if (!resp.ok) {
            throw Error("api erro")
        }
        return await resp.json()
    }

    async searchStaff(opts: SearchStaffOptions): Promise<SearchResult<Staff>> {
        const q = new URLSearchParams()
        q.set("page", `${opts.page}`)
        q.set("perPage", `${opts.perPage}`)
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/staffs`)
        if (!resp.ok) {
            throw Error("api erro")
        }
        return await resp.json()
    }

    async getProduct(productId: string): Promise<Product> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/products/${productId}`)
        if (!resp.ok) {
            throw Error("api erro")
        }
        return await resp.json()
    }
    async searchProducts(opts: SearchProductOptions): Promise<SearchProductResult> {
        const q = new URLSearchParams()
        q.set("page", `${opts.page}`)
        q.set("perPage", `${opts.perPage}`)
        let url = `/api/app/tenant=${this.tenantId}/products`
        if (opts.productGroupId != null) {
            url = `${url}/group=${opts.productGroupId}`
        }
        const resp = await fetch(url)
        if (!resp.ok) {
            throw Error("api erro")
        }
        return await resp.json()
    }
    async searchProductGroups(opts: SearchProductGroupOptions): Promise<SearchResult<ProductGroup>> {
        const q = new URLSearchParams()
        q.set("page", `${opts.page}`)
        q.set("perPage", `${opts.perPage}`)
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/product-groups`)
        if (!resp.ok) {
            throw Error("api erro")
        }
        return await resp.json()
    }

    async getSeatPayment(seatId: string): Promise<SeatPayment> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/seats/${seatId}/payment`)
        if (!resp.ok) {
            throw new Error("api error")
        }
        return await resp.json()
    }

    getImage(id: string): Promise<Image> {
        throw new Error("Method not implemented.");
    }

    async createTableOrder(seatId: string, productId: string, num: number): Promise<string> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/seats/${seatId}/orders`, {
            method: 'POST',
            body: JSON.stringify({
                productId,
                num,
            }),
        })
        if (!resp.ok) {
            throw new Error("api error")
        }
        return (await resp.json()).id
    }

    async createReserve(date: Date, name: string, email: string, tel: string, items: { productId: string, num: number }[]): Promise<string> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/reserve`, {
            method: 'POST',
            body: JSON.stringify({
                date,
                name,
                email,
                tel,
                items,
            }),
        })
        if (!resp.ok) {
            throw new Error("api error")
        }
        return (await resp.json()).id
    }

    async createTip(seatId: string, staffId: string, amount: number, message: string): Promise<string> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/seats/${seatId}/tips`, {
            method: 'POST',
            body: JSON.stringify({
                staffId,
                amount,
                message,
            }),
        })
        if (!resp.ok) {
            throw new Error("api error")
        }
        return (await resp.json()).id
    }

    async getStamp(): Promise<Stamp> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/stamp/point`)
        if (!resp.ok) {
            throw new Error("getStamp error")
        }
        return await resp.json()
    }

    async addStamp(pinCode: string): Promise<void> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/stamp/add-point`, {
            method: 'POST',
            body: JSON.stringify({
                pinCode,
            }),
        })
        if (!resp.ok) {
            throw new Error("getStamp error")
        }
    }

    async getStampGift(stampGiftId: string): Promise<StampGift> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/stamp/gifts/${stampGiftId}`)
        if (!resp.ok) {
            throw new Error("getStampGift error")
        }
        return await resp.json()
    }

    async exchangeStampGift(stampGiftID: string): Promise<void> {
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/stamp/gifts/${stampGiftID}/exchange`, {
            method: 'POST',
        })
        if (!resp.ok) {
            throw new Error('exchangeStampGift')
        }
    }

    async searchStampGifts(opts: SearchStampGiftsOptions): Promise<SearchResult<StampGift>> {
        const q = new URLSearchParams()
        q.set("page", `${opts.page}`)
        q.set("perPage", `${opts.perPage}`)
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/stamp/gifts`)
        if (!resp.ok) {
            throw Error("api error")
        }
        return await resp.json()
    }

    async searchBanners(opts: SearchBannersOptions): Promise<SearchResult<Banner>> {
        const q = new URLSearchParams()
        q.set("page", `${opts.page}`)
        q.set("perPage", `${opts.perPage}`)
        const resp = await fetch(`/api/app/tenant=${this.tenantId}/banners`)
        if (!resp.ok) {
            throw Error("api erro")
        }
        return await resp.json()
    }
}

export default ApiServer
