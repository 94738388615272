import { ComponentType, useCallback, useEffect, useState } from "react"
import liff from '@line/liff'
import { useNavigate } from "react-router-dom"
import { useServer } from "./providers/server"
import { Banner } from "../types/banner"

const Top: ComponentType<Props> = ({ View }) => {
    const server = useServer()
    const navigate = useNavigate()
    const [contents, setContents] = useState<{banners: Banner[]}>({banners: []})
    const fetchAsync = useCallback(async () => {
        const {list:banners} = await server.searchBanners({page: 1, perPage: 100})
        setContents({banners,})
    }, [server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    const handleClickTableOrder = async () => {
        try {
            if (process.env.REACT_APP_IS_LOCAL_STANDALONE === "yes") {
                const seatId = process.env.REACT_APP_DEBUG_SEAT_ID
                navigate(`/seat=${seatId}/product-groups`)
            } else {
                const ret = await liff.scanCodeV2()
                if (ret.value == null) {
                    return
                }
                // const seatId = process.env.REACT_APP_DEBUG_SEAT_ID
                const seatId = ret.value
                navigate(`/seat=${seatId}/product-groups`)
            }

        } catch (error) {
            console.log({ error })
        }
    }

    const handleClickPreOrder = async () => {
        try {
            navigate(`/reserve/product-groups`)
        } catch (error) {
            console.log({ error })
        }
    }

    const handleClickCoupon = async () => {
        try {
            navigate("/coupons")
        } catch (error) {
            console.log({ error })
        }
    }

    const handleClickTip = async () => {
        try {
            if (process.env.REACT_APP_IS_LOCAL_STANDALONE === "yes") {
                const seatId = process.env.REACT_APP_DEBUG_SEAT_ID
                navigate(`/seat=${seatId}/staffs`)
            } else {
                const ret = await liff.scanCodeV2()
                if (ret == null) {
                    return
                }
                // const seatId = process.env.REACT_APP_DEBUG_SEAT_ID
                const seatId = ret.value
                navigate(`/seat=${seatId}/staffs`)
            }
        } catch (error) {
            console.log({ error })
        }
    }

    const handleClickStamp = async () => {
        try {
            navigate("/stamp")
        } catch (error) {
            console.log({ error })
        }
    }

    const handleClickBanner = (banner:Banner) => {
        window.open(banner.url, "blank")
    }

    return <View
        onClickTableOrder={handleClickTableOrder}
        onClickTip={handleClickTip}
        onClickStamp={handleClickStamp}
        onClickCoupon={handleClickCoupon}
        onClickPreOrder={handleClickPreOrder}
        banners={contents.banners}
        onClickBanner={handleClickBanner}
    />
}

export default Top

export type TopViewType = ComponentType<{
    onClickTableOrder: () => any
    onClickPreOrder: () => any
    onClickTip: () => any
    onClickStamp: () => any
    onClickCoupon: () => any
    banners: Banner[]
    onClickBanner: (banner:Banner) => any
}>

interface Props {
    View: TopViewType
}
