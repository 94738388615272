import { ComponentType, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Staff } from "../types/staff"
import { useServer } from "./providers/server"

const StaffDetail: ComponentType<Props> = ({ View }) => {
    const { staffId } = useParams()
    const server = useServer()
    const [staff, setStaff] = useState<Staff | null>(null)
    const [isSendComplete, setIsSendComplete] = useState(false)
    const seatId = useParams().seatId!

    const getStaffAsync = useCallback(async () => {
        const staff = await server.getStaff(staffId!)
        setStaff(staff)
    }, [staffId, server])
    useEffect(() => {
        getStaffAsync()
    }, [getStaffAsync])
    const [amount, setAmount] = useState(0)
    const handleDecrementAmount = () => setAmount(amount => {
        if (amount <= 100) {
            return 0
        }
        return amount - 100
    })
    const handleSend = async () => {
        await server.createTip(seatId, staffId!, amount, "")
        setIsSendComplete(true)
        setAmount(0)
    }
    const handleClearIsSendComplete = async () => {
        setIsSendComplete(false)
    }
    if (staff == null) {
        return <></>
    }
    return (
        <View
            staff={staff!}
            amount={amount}
            setAmount={setAmount}
            amountList={amountList}
            onIncrementAmount={() => setAmount(amount => amount + 100)}
            onDecrementAmount={handleDecrementAmount}
            disableSendButton={amount <= 0}
            disableDecrementButton={amount <= 0}
            onSend={handleSend}
            isSendComplete={isSendComplete}
            onClearIsSendComplete={handleClearIsSendComplete}
        />
    )
}

export default StaffDetail

export type StaffDetailViewType = ComponentType<{
    staff: Staff
    amount: number
    amountList: number[]
    setAmount: (amount: number) => any
    onIncrementAmount: () => any
    onDecrementAmount: () => any
    disableSendButton: boolean
    disableDecrementButton: boolean
    onSend: () => any
    isSendComplete: boolean
    onClearIsSendComplete: () => any
}>

interface Props {
    View: StaffDetailViewType
}

const amountList = [500, 1000, 3000, 5000, 10000]
