import { ComponentType, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Product } from "../types/product"
import { SeatPayment } from "../types/seatPayment"
import { useReserve } from "./providers/reserve"
import { useServer } from "./providers/server"

const ReserveCart: ComponentType<Props> = ({ View }) => {
    const server = useServer()
    const { cartItems } = useReserve()
    const [formState, setFormState] = useState<{
        name: string,
        email: string,
        tel: string,
        date: Date,
    }>({
        name: "",
        email: "",
        tel: "",
        date: new Date(),
    })
    const setFormVal = <T,>(name: string) => (value: T) => {
        setFormState(formState => ({
            ...formState,
            [name]: value,
        }))
    }
    return (
        <View
            items={cartItems}
            {...formState}
            setName={setFormVal<string>('name')}
            setTel={setFormVal<string>('tel')}
            setEmail={setFormVal<string>('email')}
            setDate={setFormVal<Date|undefined>('date')}
            onSubmit={async () => {
                console.log({formState})
                await server.createReserve(formState.date, formState.name, formState.email, formState.tel, cartItems.map(v => ({
                    productId: v.product.id!,
                    num: v.num,
                })))
                alert('注文しました')
            }}
        />
    )
}

interface Props {
    View: ReserveCartViewtype
}

export default ReserveCart

export type ReserveCartViewtype = ComponentType<{
    items: { product: Product, num: number }[]
    name: string
    email: string
    tel: string
    date: Date | undefined,
    setName: (v: string) => any
    setEmail: (v: string) => any
    setTel: (v: string) => any
    setDate: (v: Date) => any
    onSubmit: () => any,
}>
