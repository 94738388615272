export interface Theme {
    mainColor: string
    features: Feature[]
}

export interface Feature {
    name: string
    enable: boolean
    topButtonSize: string
}

export const mainColorLabels = {
    'color001': 'カラー1',
    'color002': 'カラー2',
    'color003': 'カラー3',
    'color004': 'カラー4',
    'color005': 'カラー5',
    'color006': 'カラー6',
    'color007': 'カラー7',
    'color008': 'カラー8',
    'color009': 'カラー9',
    'color010': 'カラー10',
    'color011': 'カラー11',
    'color012': 'カラー12',
    'color013': 'カラー13',
    'color014': 'カラー14',
    'color015': 'カラー15',
    'color016': 'カラー16',
    'color017': 'カラー17',
    'color018': 'カラー18',
    'color019': 'カラー19',
}

export const featureNames = ["tableOrder", "preOrder", "stamp", "coupon", "tip"] as const

export const featureLabels: {[key in typeof featureNames[number]]: string} = {
    "tableOrder": "テーブルオーダー",
    "preOrder": "事前注文",
    "stamp": "スタンプ",
    "coupon": "クーポン",
    "tip": "チップ",
}