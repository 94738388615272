import { ComponentType, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { SeatPayment } from "../types/seatPayment"
import { useServer } from "./providers/server"

const TableOrderPaymentCard: ComponentType<Props> = ({ View }) => {
    const server = useServer()
    const { seatId } = useParams()
    const [seatPayment, setSeatPayment] = useState<SeatPayment | null>(null)
    const fetchAsync = useCallback(async () => {
        try {
            const item = await server.getSeatPayment(seatId!)
            setSeatPayment(item)
        } catch (error) {
            console.log({ error })
        }
    }, [seatId, server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    return (
        <View
            seatPayment={seatPayment}
        />
    )
}

interface Props {
    View: TableOrderPaymentCardViewType
}

export default TableOrderPaymentCard

export type TableOrderPaymentCardViewType = ComponentType<{
    seatPayment: SeatPayment | null
}>
