import { ComponentType, useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Product, SearchProductResult } from "../types/product"
import { SearchResult } from '../types/search'
import { useServer } from "./providers/server"

const Products: ComponentType<Props> = ({ viewComponent: View }) => {
    const seatId = useParams().seatId!
    const productGroupId = useParams().productGroupId
    const server = useServer()
    const navigate = useNavigate()
    const [fetched, setFetched] = useState(false)
    const [{ list, total, productGroup }, setResult] = useState<SearchProductResult>({ list: [], total: 0 })
    const fetchAsync = useCallback(async () => {
        setResult(await server.searchProducts({ page: 1, perPage: 10, productGroupId }))
    }, [productGroupId, server])
    useEffect(() => {
        fetchAsync().then(() => setFetched(true))
    }, [fetchAsync])
    const handleClick = (item: Product) => {
        navigate(`/seat=${seatId}/products/${item.id}`)
    }
    const title = useMemo(() => {
        if (productGroup == null) {
            return 'テーブルオーダー'
        } else {
            return productGroup.label
        }
    }, [productGroup])
    return (
        <View
            list={list}
            total={total}
            onClick={handleClick}
            title={title}
        />
    )
}

export default Products

interface Props {
    viewComponent: ProductsViewType
}

export type ProductsViewType = ComponentType<{
    list: Product[]
    total: number
    onClick: (item: Product) => any
    title: string
}>


