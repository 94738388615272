import { ComponentType, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SearchResult } from "../types/search"
import { StampGift } from "../types/stampGift"
import { useServer } from "./providers/server"

const StampGifts:ComponentType<Props> = ({View}) => {
    const navigate = useNavigate()
    const server = useServer()
    const [result, setResult] = useState<SearchResult<StampGift>>({list: [], total: 0})

    const fetchAsync = useCallback(async () => {
        try {
        setResult(await server.searchStampGifts({page: 1, perPage: 100}))
        } catch(error) {
            // alert(error)
            console.log(error)
        }
    }, [server])

    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])

    useEffect(() => {
        window.document.title = "スタンプと交換"
    }, [])

    const {list} = result

    return (
        <View
            list={list}
            onClickItem={(item:StampGift) => {
                navigate(`/stamp/gifts/${item.id}`)
            }}
        />
    )
}

export default StampGifts

interface Props {
    View: StampGiftsViewType
}

export type StampGiftsViewType = ComponentType<{
    list:  StampGift[]
    onClickItem: (item:StampGift) => any
}>
