import { ComponentType, Context, createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { Server } from "../../types/server";
import { Theme } from "../../types/theme";

export const ThemeProvider: ComponentType<PropsWithChildren<Props>> = ({ children, server }) => {
    const [theme, setTheme] = useState<Theme | undefined>(undefined)
    const fetchAsync = useCallback(async () => {
        setTheme(await server.getTheme())
    }, [server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    const value = {
        theme,
    }
    console.log({theme})
    return (
        <context.Provider value={value}>
            {children}
        </context.Provider>
    )
}

interface Props {
    server: Server,
}

interface Value {
    theme: Theme | undefined,
}

const context = createContext<Value | undefined>(undefined)

export const useThemeContext = ():Value => useContext(context)!

export const useTheme = ():Theme => useContext(context)!.theme!
