import { Box, Button, TextField, Typography } from "@mui/material";
import { ComponentType } from "react";
import { TableOrderPaymentCardViewType } from "../app/TableOrderPaymentCard";

const TableOrderPaymentCardView: TableOrderPaymentCardViewType = ({ seatPayment }) => {
    if (seatPayment == null) {
        return <></>
    }
    const total = seatPayment.totalAmount
    return (
        <Box>
            <Row>
                <Typography variant="h4">クレジットカードでお支払い</Typography>
            </Row>
            <Row>
                <Typography variant="h6">合計: {total}円</Typography>
            </Row>
            <Box>
                <Row>
                    <TextField fullWidth label="カード番号" defaultValue="" />
                </Row>
                <Row>
                    <Typography>有効期限</Typography>
                    <TextField label="月" defaultValue="" /><TextField label="年" defaultValue="" />
                </Row>
                <Row>
                    <TextField label="セキュリティーコード"></TextField>
                </Row>
                <Row>
                    <Button color="secondary" variant="contained" size="large">お支払いを完了する</Button>
                </Row>
            </Box>
        </Box>
    )
}

export default TableOrderPaymentCardView

const Row: ComponentType<{}> = ({ children }) => {
    return (
        <Box sx={{ p: 1 }}>
            {children}
        </Box>
    )
}